@import "theme";
@import "~bootstrap/scss/bootstrap";
@import "overrides";
@import "variables";
@import "colors";
@import "fonts";

body {
  margin: 0;
  font-family: $font-family-en;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $body-bg;
  font-size: $font-size-base;
}

.mainAR {
  font-family: $font-family-ar;
}

.mainEN {
  font-family: $font-family-en;
}
