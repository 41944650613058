$primary:       #cf0921 !default;
$secondary:     #F06543 !default;
$success:       #2a7b3a !default;
$danger:        red     !default;
$light:         #E8E9EB !default;
$dark:          #313638 !default;

$body-bg: $light;

$white: white;
