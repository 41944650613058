@import "styles/colors";

.resultTitle {
  border-bottom: unset;
  padding-bottom: 10px;
  font-weight: normal;
}

.result {
  width: 100%;
  margin-top: 20px;
}

.resultItems {
  display: flex;
  flex-wrap: wrap;
}
