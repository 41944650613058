@import "styles/colors";
@import "styles/variables";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;

  @media (max-width: $mobile-max) {
    margin-top: 50px;
  }
}

.logo {
  width: 60px;
  margin-inline-end: 20px;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
